.wp-cell-message {
  height: 56px;
  background: var(--bs-gray-200);

  @media (max-width: 639px) {
    height: 48px;
  }

  &-empty {
    background: none!important;
  }
}

.wp-dark-mode .wp-cell-message {
  background: var(--bs-dark)
}

.wp-cell-message-desktop {
  display: inline-flex;

  @media (max-width: 639px) {
    display: none;
  }
}

.wp-cell-message-mobile {
  display: none;

  @media (max-width: 639px) {
    display: inline-flex;
  }
}

.wordplay-message a {
  text-decoration: underline;
}
