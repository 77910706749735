@import './Around/dist/css/theme.scss';

:root {
  --wordplay-green: #8fc941;
  --wordplay-green-dark: #79ae31;
  --wordplay-orange: #ffb000;
  --wordplay-orange-dark: #d79500;
}

.wp-high-contrast-mode {
  --wordplay-green: #648fff;
  --wordplay-green-dark: #2b66ff;
  // --wordplay-green: #f5793a;
  // --wordplay-green-dark: #f25a0c;
  // --wordplay-orange: #85c0f9;
  // --wordplay-orange-dark: #4ba2f6;
}

body {
  font-weight: 500;
  background-color: #f8fafc;

  &.wp-dark-mode {
    background-color: var(--bs-gray-dark);
  }
}

.wp-footer {
  max-width: 520px;
  margin-top: 20px;
  border-top: 1px solid var(--bs-gray-300)
}

.wp-dark-mode {
  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1, .form-label, dt, dd, label, a.nav-link {
    color: rgb(255, 255, 255, 0.7);
  }
  .card, .toast {
    background-color: var(--bs-dark);
  }
  .form-control {
    border-color: var(--bs-gray-500);
    background-color: var(--bs-dark);
  }
  .wp-footer, .toast-header, .card-header {
    border-color: var(--bs-gray-500);
  }
  .toast-header, .card-header {
    border-color: var(--bs-gray-dark);
  }
  .list-group-item, .toast-header {
    border-color: var(--bs-gray-dark);
    background-color: var(--bs-dark);
  }
}

button {
  appearance: none;
  border: none;
}

.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.cell-wiggle {
  animation: wiggle linear;
  animation-duration: 0.3s;
}

@keyframes wiggle {
  0% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0);
  }
}

.cell-grid {
  display: grid;
  grid-template-columns: 1fr auto minmax(250px, 1fr);

  @media (max-width: 639px) {
    grid-template-columns: 1fr auto minmax(0px, 1fr);
  }

  .no-guesses {
    grid-template-columns: 1fr auto minmax(0px, 1fr);
  }
}

.toast,
.wp-modal {
  animation: wpFadeIn linear;
  animation-duration: 0.2s;
}

@keyframes wpFadeIn {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
