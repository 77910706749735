.wz-pie-chart {
  display: flex;
  align-items: center;

  .ct-series-a .ct-slice-pie {
    fill: #22c55e;
  }
  .ct-series-b .ct-slice-pie {
    fill: #ef4444;
  }
  .ct-series-c .ct-slice-pie {
    fill: #0ea5e9;
  }
  .ct-series-d .ct-slice-pie {
    fill: #f97316;
  }
  .ct-series-e .ct-slice-pie {
    fill: #a855f7;
  }
  .ct-series-f .ct-slice-pie {
    fill: #eab308;
  }
}
