.wp-cell {
  width: 56px;
  height: 56px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  border-radius: 6px;
  margin: 0 2px;

  @media (max-width: 639px) {
    width: 48px;
    height: 48px;
  }

  &-absent {
    border-color: var(--bs-gray-500);
    background: var(--bs-gray-500);
    color: white;
  }

  &-present {
    border-color: var(--wordplay-orange-dark);
    background: var(--wordplay-orange);
    color: white;
  }

  &-correct {
    border-color: var(--wordplay-green-dark);
    background: var(--wordplay-green);
    color: white;
  }

  &-typing {
    border-style: dashed;
    border-color: black;
    background: white;
    color: black;
  }

  &-invalid {
    border-color: var(--bs-danger);
    background: white;
    color: var(--bs-danger);
  }

  &-undefined {
    border-color: black;
    background: white;
    color: black;
  }
}

.wp-dark-mode .wp-cell {
  &-typing {
    border-color: var(--bs-gray-500);
    background: var(--bs-gray-dark);
    color: var(--bs-gray-500);
  }

  &-invalid {
    background: var(--bs-gray-dark);
  }

  &-undefined {
    border-color: var(--bs-gray-500);
    background: var(--bs-gray-dark);
    color: var(--bs-gray-500);
  }
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 400px;
  width: 56px;
  height: 56px;
  margin: 0 2px;

  @media (max-width: 639px) {
    width: 48px;
    height: 48px;
  }
}
/* flip the pane when hovered */
.flip-container.flip-hover .flipper {
  transform: rotateY(180deg);
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
  width: 100%;
  height: 100%;
}

.flipper-speedy {
  transition: 0s!important;
}

/* hide back of pane during swap */
.flip-front,
.flip-back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  border-radius: 6px;
}

/* flip-front pane, placed above back */
.flip-front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);

  border-color: black;
  background: white;
  color: black;
}

/* back, initially hidden pane */
.flip-back {
  transform: rotateY(180deg);
}

.wp-dark-mode .flip-front {
  border-color: var(--bs-gray-500);
  background: var(--bs-gray-dark);
  color: var(--bs-gray-500);
}