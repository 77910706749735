.wordplay-blog {
  color: #1e293b;
  font-size: 18px;

  @media (max-width: 640px) {
    font-size: 16px;
  }

  p {
    margin-bottom: 33px;
    line-height: 1.8;
  }
  ul,
  ol {
    margin-bottom: 33px;
    padding-left: 20px;
    list-style: disc;
  }
  li {
    line-height: 1.8;
  }
  h1 {
    margin-bottom: 33px;
    line-height: 1.2;
    font-weight: 700;
    font-size: 40px;
    @media (max-width: 640px) {
      font-size: 32px;
    }
  }
  h2 {
    margin-top: 66px;
    margin-bottom: 33px;
    line-height: 1.3;
    font-weight: 700;
    font-size: 28px;
    @media (max-width: 640px) {
      font-size: 24px;
    }
  }
  h3 {
    margin-bottom: 6px;
    line-height: 1.4;
    font-weight: 700;
    font-size: 22px;
    @media (max-width: 640px) {
      font-size: 18px;
    }
  }
  h4,
  h5,
  h6 {
    margin-bottom: 5px;
    line-height: 1.4;
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 640px) {
      font-size: 16px;
    }
  }
  a {
    text-decoration: underline;
    color: #3b82f6;
  }
  img {
    margin: 0 auto 33px;
    display: block;
    max-width: 100%;
  }
  figcaption {
    position: relative;
    bottom: 20px;
    line-height: 1.5;
    color: #64748b;
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 640px) {
      font-size: 14px;
    }
    & + p {
      margin-bottom: 13px;
    }
  }
  blockquote {
    margin: 0 0 33px;
    padding-left: 33px;
    border-left: 5px solid #3b82f6;
    * {
      color: #1e293b;
      font-style: italic;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    cite {
      display: block;
      text-align: right;
      font-style: normal !important;
      font-weight: 700;
    }
  }
  em {
    background: none;
    font-style: italic;
  }
  i {
    font-style: italic;
  }
  hr {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 4px;
    width: 200px;
    border: none;
    background: #3b82f6;
  }
  mark {
    background: #fef3c7;
  }
  @media (max-width: 767px) {
    br {
      display: block;
    }
  }
}

.wp-dark-mode .wordplay-blog {
  color: white;
  a {
    color: #60a5fa;
  }
  figcaption {
    color: #94a3b8;
  }
  blockquote {
    border-left: 5px solid #60a5fa;
    * {
      color: white;
    }
  }
  hr {
    background: #60a5fa;
  }
  mark {
    background: #92400e;
  }
}
