.wp-key {
  height: 48px;
  padding: 0;
  width: 100%;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.075s ease-in;

  @media (max-width: 639px) {
    height: 40px;
  }

  &:hover {
    transform: scale(1.06);
  }
  &:active {
    transform: scale(1);
  }

  &-absent {
    background: var(--bs-gray-500);
    color: white;
  }

  &-present {
    background: var(--wordplay-orange);
    color: white;
  }

  &-correct {
    background: var(--wordplay-green);
    color: white;
  }

  &-undefined {
    background: var(--bs-gray-300);
    color: black;
  }

  &-type-space {
    max-width: 260px;
  }

  &-type-action {
    max-width: 65px;
  }

  &-type-letter {
    max-width: 40px;
  }
}