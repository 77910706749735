.wp-message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 76px;

  a {
    color: inherit;
    text-decoration: underline;
  }

  &-danger {
    color: var(--bs-red);
    background: rgba(var(--bs-danger-rgb), 0.25);
  }

  &-info {
    color: var(--bs-blue);
    background: rgba(var(--bs-info-rgb), 0.25);
  }

  &-primary {
    color: var(--bs-purple);
    background: rgba(var(--bs-primary-rgb), 0.25);
  }

  &-secondary {
    color: var(--bs-gray);
    background: rgba(var(--bs-secondary-rgb), 0.25);
  }

  &-success {
    color: var(--bs-green);
    background: rgba(var(--bs-success-rgb), 0.25);
  }

  &-warning {
    color: var(--bs-orange);
    background: rgba(var(--bs-warning-rgb), 0.25);
  }
}

.wp-dark-mode .wp-message{
  &-danger {
    color: white;
    background: var(--bs-danger);
  }

  &-info {
    color: white;
    background: var(--bs-info);
  }

  &-primary {
    color: white;
    background: var(--bs-primary);
  }

  &-secondary {
    color: white;
    background: var(--bs-secondary);;
  }

  &-success {
    color: white;
    background: var(--bs-success);
  }

  &-warning {
    color: white;
    background: var(--bs-warning);
  }
}