.wp-navigation {
  background-color: var(--bs-dark);
}

.wp-toggle-dark-mode {
  width: 20px;
  color: rgb(203 213 225);
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: white
  }
}
